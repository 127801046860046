import React from "react";
import {
  PageLayout,
  FoodMenu,
  MenuCartDisplay,
  CartHOC,
} from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class ProductsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      allFishermanBusinessMenuSchedule,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuItem,
      allFishermanBusinessMenuModifierSet,
      allFishermanBusinessMenuModifier,
      fishermanBusiness,
    } = data;

    return (
      <WebsiteLayout>
        <PageLayout>
          <Grid stackable={true} verticalAlign={"middle"} textAlign={"left"}>
            <Grid.Column width={16}>
              <div>
                <div className={"hidden-container"}>
                  <FoodMenu
                    header={""}
                    centerNavigation={true}
                    categoryDisplayVariant={"HorizontalCardRow"}
                    itemDisplayVariant={"HorizontalCard"}
                    menu={{
                      schedules: allFishermanBusinessMenuSchedule.nodes,
                      categories: allFishermanBusinessMenuCategory.nodes,
                      items: allFishermanBusinessMenuItem.nodes,
                      modifierSets: allFishermanBusinessMenuModifierSet.nodes,
                      modifiers: allFishermanBusinessMenuModifier.nodes,
                    }}
                    business={{
                      id: fishermanBusiness._id,
                      type: fishermanBusiness.type,
                    }}
                  />
                  <MenuCartDisplay
                    cart={{ items: [] }}
                    menu={{
                      schedules: allFishermanBusinessMenuSchedule.nodes,
                      categories: allFishermanBusinessMenuCategory.nodes,
                      items: allFishermanBusinessMenuItem.nodes,
                      modifierSets: allFishermanBusinessMenuModifierSet.nodes,
                      modifiers: allFishermanBusinessMenuModifier.nodes,
                    }}
                  />
                  <CartHOC
                    hours={fishermanBusiness.primaryLocation.hours}
                    timezone={fishermanBusiness.primaryLocation.timezone}
                    locationId={fishermanBusiness.primaryLocation.timezone}
                  />
                </div>
                <div>
                  <CartHOC
                    hours={fishermanBusiness.primaryLocation.hours}
                    timezone={fishermanBusiness.primaryLocation.timezone}
                  >
                    {(cart) => (
                      <div className="food-menu-cart-container">
                        <div>
                          <FoodMenu
                            menu={{
                              schedules: allFishermanBusinessMenuSchedule.nodes,
                              categories:
                                allFishermanBusinessMenuCategory.nodes,
                              items: allFishermanBusinessMenuItem.nodes,
                              modifierSets:
                                allFishermanBusinessMenuModifierSet.nodes,
                              modifiers: allFishermanBusinessMenuModifier.nodes,
                            }}
                            header=""
                            disableModifiers
                            disableSchedules
                            categoryDisplayVariant="HorizontalCardRow"
                            itemDisplayVariant="HorizontalCard"
                            cart={cart}
                          />
                        </div>
                        <div>
                          <MenuCartDisplay
                            cart={cart}
                            menu={{
                              schedules: allFishermanBusinessMenuSchedule.nodes,
                              categories:
                                allFishermanBusinessMenuCategory.nodes,
                              items: allFishermanBusinessMenuItem.nodes,
                              modifierSets:
                                allFishermanBusinessMenuModifierSet.nodes,
                              modifiers: allFishermanBusinessMenuModifier.nodes,
                            }}
                            locationId={fishermanBusiness.primaryLocation._id}
                          />
                        </div>
                      </div>
                    )}
                  </CartHOC>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    allFishermanBusinessMenuSchedule(filter: { name: { eq: "Products" } }) {
      nodes {
        availableDays
        categories
        _id
        name
      }
    }
    allFishermanBusinessMenuCategory {
      nodes {
        items
        description
        name
        _id
      }
    }
    allFishermanBusinessMenuItem {
      nodes {
        modifierSets
        description
        _id
        name
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        variations {
          _id
          menuItem
          name
          order
          price
        }
        annotations {
          likeCount
        }
      }
    }
    allFishermanBusinessMenuModifierSet {
      nodes {
        _id
        minAllowed
        modifiers
        name
      }
    }
    allFishermanBusinessMenuModifier {
      nodes {
        _id
        name
        price
      }
    }
    fishermanBusiness {
      _id
      type
      primaryLocation {
        hours {
          day
          open
          close
          label
        }
        timezone
        _id
      }
    }
  }
`;
